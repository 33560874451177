<template>
  <div>
    <!--    show true 显示模式 否则为编辑模式-->
    <person v-model="data" :form="form" :show="true" @change="change"></person>
  </div>
</template>

<script>
import person from "../demo/person";
import {
  getField
} from "@/api/admin/exam/examRecord.js"
import {
  getKsbmk
} from "@/api/admin/exam/createExamku.js"
import {
  covertToForm
} from "@/api/common/draggable.js";
export default {
  props:{
    ksbmh:String,
    sfzjh:String,
  },
  components: {
    person
  },
  data() {
    return {
      // id 唯一ID
      // name: 显示的label
      // bound: 绑定form的key
      // nameBound: label绑定绑定form的key
      // width: 20 33 66 50 100
      // listId: 和头像一个容器为1 下面的容器则为2
      data: [
      ],
      form: {
      }
    }
  },
  methods: {
    // 报名字段
    getRegisterField(ksbmh) {
      getField({ksbmbh:ksbmh}).then(res => {
          if (res.status){
            this.data = covertToForm(res.data)
          }
      })
    },
    // 考生报名数据
    getRegisterInfo(ksbmh, sfzjh) {
      getKsbmk({ksbmbh:ksbmh, sfzjh:sfzjh}).then(res => {
          if (res.status){
            this.form = res.data[0];
          }
      })
    },
    change() {
      
    }
  },
  mounted() {
    console.log(this.ksbmh)
    this.getRegisterField(this.ksbmh)
    this.getRegisterInfo(this.ksbmh, this.sfzjh)
  }
}
</script>
<style lang="scss" scoped>
</style>