<template>
  <div>
    <person-use :ksbmh="ksbmh" :sfzjh="sfzjh"></person-use>
  </div>
</template>

<script>
import PersonUse from "@/components/user/person-use.vue";

export default {
  components: {
    PersonUse,
  },
  data() {
    return {
      ksbmh: "20220003",
      sfzjh: "350582199401294031"
    };
  },

  mounted() {

  },

  methods: {},
};
</script>

<style lang="scss" scoped>

</style>